.table-container {
  display: flex;
  height: 100%;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;

  .table {
    margin-bottom: 50px !important;
    margin-top: 50px !important;
  }
}

.table-header {
  font-size: 24px !important;
  height: 50px !important;
}

.table-links {
  color: lightgreen;
}

.table-delete {
  margin-left: 30px !important;
}
